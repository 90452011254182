import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown, Tabs, Tab, NavItem} from "react-bootstrap";
import "./SimilarProperties.scss";
import axios from 'axios';
import { numberWithCommas } from "@Components/common/site/common-functions"
import FsLight from './FsLight';
import {
    FORSALE_PAGE_URL,
    TORENT_PAGE_URL
  } from "@Components/common/site/constants";
  import CustomVideoPlay from "@Components/CustomVideo/CustomVideo.js";
import FloorPlanModal from "@Components/FloorPlanModal/floorPlanModal";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";

import {ImageModule} from "@Components/common/Image_Module";


export const uknumberformat = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}

const SimilarProperties = (props) => {
    
    const [toggler, setToggler] = useState(false);
    // const [togglerindex, setTogglerindex] = useState(false);
    const togglerclick = (i) => {
        setToggler(true)
        // setTogglerindex(i)
    }
    const [propItems, setPropItems] = useState(false)
    const getitems = async url => {
      try {
        const { data } = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }});
        // console.log('PropertySimilar', data);
        setPropItems(data)
      } catch (error) {
        //console.error(error)
      }
    }
  
    useEffect(() => {
  
      let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + props.pid;
  
      getitems(url)
    },[])
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
             {
            breakpoint: 9999,
            
            },
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:true,
                },
              },
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:true,
                },
              },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:true,
                },
              },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots:true,
            },
          },
        ],
      }
    return (propItems && propItems.length > 0 ?
        <React.Fragment>
            <section className="similar-properties">
               <Container>
                   <Row>
                       <Col lg={12}>
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                           <h2>Similar Properties in {props.address}</h2>
                        </ScrollAnimation>
                           </Col>
                   </Row>
                   <Row className="feature-properties-row">
                       <Col lg={12}>
                            <Slider {...settings}>
                            {propItems && propItems.map((hit, i) => {
                                let processedImages = JSON.stringify({});
                                if (hit?.imagetransforms?.Image_Transforms) {
                                    processedImages = hit.imagetransforms.Image_Transforms;
                                  }
                                return (
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    
                                   
                                <div>
                                <div className="similar-property-card-main">
                                    <div className="similar-property-img-content">
                                        <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                                        <Link to={hit.search_type === 'lettings' ? TORENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id : FORSALE_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>
                                        {/* <picture>
                                        <source media="(min-width:992px)" srcSet={hit.images[0].url} />
                                        <source media="(min-width:768px)" srcSet={hit.images[0].url} />
                                        <img src={hit.images[0].url} alt="" />
                                        </picture> */}
                                        <ImageModule ImageSrc={hit.images[0]} attr={{ alt: hit?.Name }} ggfx_results={hit.ggfx_results} imagename="property.images.similarpropertytile" strapi_id={hit.id} />

                                        {/* <ImageTransform imagesources={hit.images[0]?.url} renderer="srcSet" imagename='property.images.similarpropertytile'
                                          attr={{ alt: '', className: '' }}
                                          imagetransformresult={processedImages} id={hit.id} >

                                        </ImageTransform> */}
                                        </Link>
                                        </div>
                                        <div className="icon-group">
                                        <ul className="img-icons">
                                            {/* {hit?.virtual_tour} */}
                                        {hit?.virtual_tour && hit?.video_link ? 
                            <CustomVideoPlay small={true} title={hit?.title} url={hit?.virtual_tour}/> : null}
                                            {hit?.floorplan[0]?.url && <FloorPlanModal Image_Transforms={hit?.imagetransforms?.Image_Transforms} id={hit.id} floorplanimg={hit?.floorplan[0]?.url} />}
                                            {/* {hit?.video_link ? <li><i class="icon-video-dark"></i></li> : null} */}
                                            
                                            <FsLight
        images={hit.images}
length={hit.images?.length}
    />
                                            <li><span>{hit.images?.length}</span></li>
                                        </ul>
                                        </div>
                                        
                                    </div>
                                    <div className="similar-property-text-content">
                                    <Link to={hit.search_type === 'lettings' ? TORENT_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id : FORSALE_PAGE_URL.alias+'/'+hit.slug+'-'+hit.id}>
                                        <div className="property-amount-text">
                                            <span className="amount">&#163;{numberWithCommas(hit.price)}</span>
                                            {hit.search_type === "lettings" ? <span className="guide-text">PCM</span> : hit.search_type === "sales" ? <span className="guide-text">{hit.price_qualifier}</span> : null}
                                            {/* {hit.price_qualifier ? <span className="guide-text">{hit.price_qualifier}</span> : null} */}
                                        </div>
                                        <div className="property-description">
                                            <p>{hit.display_address}</p>
                                        </div>
                                        {/* {JSON.stringify(hit.crm_negotiator_id)} */}
                                        <div className="item-user-details">
                                            <span className="contact-number">
                                            {/* <picture>
                                            <source media="(min-width:992px)" srcSet={item.UserImg} />
                                            <source media="(min-width:768px)" srcSet={item.UserImg} />
                                            <img src={item.UserImg} alt="" />
                                            </picture>   */}
                                            {hit?.crm_negotiator_id?.Mobile?.value &&
                                            <Link href={`tel: ${hit.crm_negotiator_id?.Mobile?.value}`} class="btn-text-link">{uknumberformat(hit.crm_negotiator_id?.Mobile?.value)}</Link>
                                            }
                                            </span>
                                            {/* <span class="wishlist  icon-round-box"><a href="#"><span class="icon-wrap"><i class="icon-wishlist-small"></i></span></a></span> */}
                                            {/* <span className="text-save d-none d-md-inline-flex"><Link href="#">Save</Link></span> */}
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                                </div>                                
                                </ScrollAnimation>
                              )
                            })}
                             </Slider>
                            
                       </Col>
                   </Row>
               </Container>
            </section>
        </React.Fragment>
   : <></> )
}

export default SimilarProperties;